.SlideContainer {
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-end;
}

.gallery {
  justify-content: center;
}

.SlideInfo {
  height: 100%;
  padding-left: 100px;
  padding-right: 400px;
  position: absolute;
  width: 60%;
  left: 0;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 62%,
    rgba(0, 0, 0, 0)
  );

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
}
.SlideInfo a {
  text-decoration: none;
}
.SliderImage {
  height: 100%;
}
.SliderScores {
  position: absolute;
  bottom: 0;
  left: 0;
}
.Title {
  display: flex;
  align-items: center;
}
.Title h1 {
  margin: 20px 0;
  font-size: 52px;
  font-weight: 600;
  line-height: 1em;
  color: #e03a3c;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.Title i {
  color: #57575d;
  font-size: 25px;
}
.Caption {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: lighter;
  font-size: 32px;
  font-style: italic;
  line-height: 1.2em;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}
.CaptionContainer {
  margin-bottom: 10px;
}
.LogoImg img {
  width: 400px;
  max-width: 400px;
  margin-top: 20px;
}
.Overview {
  font-size: 16px;
  color: #ccc;
  padding-right: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.Loading {
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1800px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 56%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 1700px) {
  .Title h1 {
    font-size: 42px;
  }
  .LogoImg img {
    width: 300px;
  }
  .Caption {
    font-size: 28px;
  }
  .SlideInfo {
    padding-right: 250px;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 1600px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 62%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 1500px) {
  .LogoImg img {
    max-width: 250px;
  }
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 55%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 1450px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 52%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 1360px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 44%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 1250px) {
  .SlideInfo {
    width: 80%;
    padding-right: 200px;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0)
    );
  }
  .CaptionContainer {
    margin-bottom: 10px;
  }
  .LogoImg img {
    max-width: 200px;
  }
}
@media (max-width: 1150px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 1090px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 66%,
      rgba(0, 0, 0, 0)
    );
  }
  .Caption {
    font-size: 24px;
  }
}
@media (max-width: 960px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 58%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 890px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 52%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 800px) {
  .SlideInfo {
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 45%,
      rgba(0, 0, 0, 0)
    );
  }
}
@media (max-width: 768px) {
  .SliderImage {
    width: 100%;
    height: auto;
  }
  .LogoImg img {
    margin-top: 10px;
  }
  .SlideInfo {
    position: absolute;
    padding-right: 0;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
  }
  .Caption {
    font-size: 22px;
  }
  .Overview p {
    color: #fff;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  }
}
@media (max-width: 500px) {
  .Title h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .SlideInfo {
    padding-left: 20px;
    width: 90%;
    min-width: 90%;
  }
  .SliderContainer {
    justify-content: center;
  }
  .SliderImage {
    height: 100%;
    width: initial;
  }
  .Caption {
    font-weight: normal;
    color: #fff;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.awssld {
  background: #000;
  height: 620px;
}
.awssld__container {
  padding-bottom: 0;
}
.awssld__startUp,
.awssld__content {
  background: none;
}
.awssld__content > div:first-child {
  width: 100%;
  height: 100%;
  min-width: 100%;
  position: static;
}
.awssld__prev,
.awssld__next {
  width: 60px !important;
}
.awssld__controls__arrow-left,
.awssld__controls__arrow-right {
  height: 20px;
}
.awssld__bullets {
  z-index: 2;
}
.awssld__bullets button {
  background: #ccc;
  width: 8px;
  height: 8px;
  margin: 10px;
}
.awssld__bullets--active {
  background: #e03a3c !important;
}
.awssld__controls__arrow-left::after,
.awssld__controls__arrow-left::before,
.awssld__controls__arrow-right::after,
.awssld__controls__arrow-right::before {
  background: #fff;
  box-shadow: 3px 3px 3px #000;
}

@media (max-width: 1600px) {
  .awssld {
    height: 620px;
  }
}
@media (max-width: 1250px) {
  .awssld {
    height: 520px;
  }
}
@media (max-width: 1090px) {
  .awssld {
    height: 450px;
  }
}
@media (max-width: 500px) {
  .awssld {
    height: 350px;
  }
}
.SliderBox {
  min-height: 620px;
}
.Loading {
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1600px) {
  .SliderBox {
    min-height: 420px;
  }
}
@media (max-width: 1250px) {
  .SliderBox {
    min-height: 290px;
  }
}
@media (max-width: 1090px) {
  .SliderBox {
    min-height: 281px;
  }
}
@media (max-width: 50px) {
  .SliderBox {
    min-height: 250px;
  }
}
